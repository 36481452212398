
import { EnumCols, PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase { 
    service = new RelatorioService(); 
    item = new Relatorio();
    overlay: boolean = false;
    filter:any ={};

    mounted() {
        this.filter = this.$route.query;
        this.overlay = true; 
        
        this.service.ListarParcelasPriceSac(this.filter.empreendimentoId, this.filter.numeroContrato, this.filter.formaPagamento,  this.filter.clienteId, this.filter.situacaoParcelaId, this.filter.contratoId, this.filter.tipoReceitaId, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataBaixaInicial, this.filter.dataBaixaFinal, this.filter.empresaId)
            .then(
                res => {   
                    this.item = res.data;
                 }, 
                err => {  
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }).finally(() => {
                this.overlay = false;
            });
    } 
}

class Relatorio {
    public quantidadeParcelasTotal:number = 0;
    public jurosTotal:number = 0;
    public descontoTotal:number = 0;
    public valorTotal:number = 0;
    public valorPagoTotal:number = 0;
    public parcelas: RelatorioParcela[] = [];
}

class RelatorioParcela {
    public encargosTotal:number = 0;
    public numeroSequenciaContrato:string = "";
    public parcelaNumero:number = 0;
    public totalParcelas:number = 0;
    public clienteNome:string ="";
    public empreendimentoNome:string =''; 
    public unidadeQuadra:string =''; 
    public unidadeLote:string ='';   
    public tipoReceita:string ='';    
    public vencimentoParcela:string ='';   
    public dataPagamentoParcela:string ='';   
    public valorParcela:number = 0;  
    public situacaoParcela:string ='';   
    public formaPagamentoParcela:string ='';   
    public jurosParcelaBaixa:number =0  
    public multaParcelaBaixa:number =0 
    public descontosParcelaBaixa:number =0   
    public valorPagoParcela:number =0   
    public valorTotalPagoParcela:number =0   
    public parcelasAgrupadas:string ='';     
    public empresaNome: string ='';
    public amortizacao:number = 0;
    public correcaoMonetaria:number = 0;
    public juros:number = 0;
    public mpi:number = 0;
    public dfi:number =0;
    public gestao:number = 0;
    public valorSemAdicionais:number = 0;
}
